import * as React from "react";
import { Helmet } from "react-helmet";
import { withPrefix, Link } from "gatsby";

import BottomLeftCorner from "../svg/bottom_left_corner.svg";
import BottomRightCorner from "../svg/bottom_right_corner.svg";
import MainSectionLogo from "../svg/main_section_logo.svg";
import TopLeftCorner from "../svg/top_left_corner.svg";
import TopRightCorner from "../svg/top_right_corner.svg";
import { FRONT_DOMAIN } from "../config";

import "../scss/main.scss";
import {useEffect} from "react";
import ChangeLanguage from "../components/changeLanguage";

const DigitalCaveCnPage = ({location}) => {

    React.useEffect(() => {
        const scrollContainer = document.querySelector('.site-wrapper');

        if(scrollContainer) {
            scrollContainer.scrollTop = 0;
        }
    }, []);

    useEffect(() => {
        document.body.classList.add('_without-layout');
    }, []);

    return (
        <>
            <Helmet>
                <title>Digital Cave | Teleport</title>
                <script src={withPrefix('inobounce.js')}></script>
                <meta property="og:image" content={`${FRONT_DOMAIN}/img/share_default.jpg`}/>
            </Helmet>

            <section className="installation-img-section">
                <img className="" src="/static/img/8_DigitalCave.jpg" alt=""/>
            </section>

            <section className="site-section site-section--installation">
                <ChangeLanguage location={location}/>
                <div className="content-section" style={ { "--width": "72rem" } }>

                    <h2 className="content-section__title">DIGITAL CAVE</h2>

                    <div className="content-section__text">
                        <p>DIGITAL CAVE 是一個由聖彼得堡頂尖視聽藝術家團隊 TRIBE特別為此展而創作藝術品。仿如走進穿梭未來與現實的通道，此空間採用8200粒閃爍LED燈組成的幻燈洞穴，隨着音樂輕快旋律，即時演變出多種變幻莫測的顏色圖案，讓我們走進一個美妙的視覺旅程，同時為大家留下深刻印象及回憶。</p>
                    </div>


                    <br/>

                    <div className="green">
                        <h2 className="content-section__title">藝術團隊 — TRIBE</h2>
                        <div className="content-section__text">
                            <p>TRIBE 是一個於 2017 年在聖彼得堡成立的視聽藝術團體。他們的作品包括沉浸式燈光和聲音裝置，並已在世界各地展出：紐約時代廣場屏幕；中國寧波的寧波展； 俄羅斯聖彼得堡的GAMMA 節；葉卡捷琳堡的 Pixels Fest；俄羅斯喀山的NUR 節。</p>
                        </div>
                    </div>

                    <MainSectionLogo className="content-section__logo" />

                </div>
            </section>
        </>
    )
}

export default DigitalCaveCnPage;
